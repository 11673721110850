import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
    Typography,
    TextField,
    CircularProgress,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Snackbar,
    Alert,
    Popover,
    Stack
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import CloseIcon from '@mui/icons-material/Close'
import ButtonWithConfirmPrompt from '../ButtonWithConfirmPrompt'
import '../CheckShift/checkShift.css'
import { AxiosWithAuth } from '../../Utilities/authenticationService'
import { desktopMonthlyBaseCellStyle } from '../CheckShift/Calendars/calendarStyles'
import MonthlyShiftLeftmostBlock from './MonthlyShiftLeftmostBlock'
import { computeBackgroundColor } from './MonthlyShiftHeader'

const EditMonthlyShiftBody = ({ employments, monthlyShift, dates, setMonthlyShift }) => {
    const { employmentId, calendarYearMonth } = useParams()
    const [shiftModalData, setShiftModalData] = useState({})
    const [shiftModalOpen, setShiftModalOpen] = useState(false)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [startError, setStartError] = useState(false)
    const [endError, setEndError] = useState(false)
    const [snackbarError, setSnackbarError] = useState('')
    const fixedCells = ['workday', 'holiday', 'totalHours', 'totalPay']

    const reloadShifts = () => {
        AxiosWithAuth.get('/shifts/monthly', {
            params: {
                employment_id: employmentId,
                date: calendarYearMonth + '-01'
            }
        }).then(res => {
            setMonthlyShift({
                ...monthlyShift,
                shifts: res.data.confirmed_or_released_shifts,
                submittedShifts: res.data.submitted_shifts,
                shiftHourPerDay: res.data.daily_shift_hour,
                confirmedEmployeeMonthlyShiftHours: res.data.confirmed_employee_monthly_shift_hour,
                shiftAlerts: res.data.shift_alerts_for_month
            })
            setShiftModalOpen(false)
            setShiftModalData({})
        })
        setSnackbarOpen(true)
    }
    const editShiftModal = () => {
        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{ p: 2 }}>勤務時間</Typography>
                    <CloseIcon
                        sx={{ p: 2, '&:hover': { color: 'lightgray' } }}
                        onClick={() => setShiftModalOpen(false)}
                    />
                </div>
                <Stack margin={2} spacing={1.5} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <TextField
                        type="time"
                        label="開始時間"
                        disabled={shiftModalData.status === 'blocked'}
                        value={shiftModalData.startTime}
                        onChange={e => setShiftModalData({ ...shiftModalData, startTime: e.target.value })}
                        sx={{ marginTop: '.5rem' }}
                        inputProps={{ step: 900 }}
                        InputLabelProps={{ shrink: true }}
                        error={startError}
                        helperText={startError ? '15分単位でのスケジュールが可能です。' : null}
                    />
                    <TextField
                        type="time"
                        label="終了時間"
                        disabled={shiftModalData.status === 'blocked'}
                        value={shiftModalData.endTime}
                        onChange={e => setShiftModalData({ ...shiftModalData, endTime: e.target.value })}
                        sx={{ marginTop: '2rem' }}
                        inputProps={{ step: 900 }}
                        InputLabelProps={{ shrink: true }}
                        error={endError}
                        helperText={endError ? '15分単位でのスケジュールが可能です。' : null}
                    />
                </Stack>
                <Stack direction="row" justifyContent="center" margin={2} spacing={4}>
                    <ButtonWithConfirmPrompt
                        component={
                            <Button
                                variant="outlined"
                                style={{ margin: '5px 5px 5px 5px' }}
                                startIcon={<DeleteIcon />}
                                disabled={shiftModalData.status === 'blocked'}
                                color="error"
                            >
                                削除
                            </Button>
                        }
                        title="シフトの削除"
                        content="削除してもよろしいですか？"
                        confirmText="削除する"
                        handleConfirm={() => {
                            AxiosWithAuth.delete('/shifts/' + shiftModalData.shiftId).then(() => reloadShifts())
                        }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ margin: '5px 5px 5px 5px' }}
                        disabled={startError || endError || shiftModalData.status === 'blocked'}
                        onClick={() => {
                            const startTime = new Date(shiftModalData.date + ' ' + shiftModalData.startTime)
                            const endTime = new Date(shiftModalData.date + ' ' + shiftModalData.endTime)
                            if (startTime.getHours() > endTime.getHours()) {
                                endTime.setDate(endTime.getDate() + 1)
                            }
                            AxiosWithAuth.put('/shifts/' + shiftModalData.shiftId, {
                                start_time: startTime,
                                end_time: endTime,
                                employment_id: shiftModalData.employment.id,
                                status: shiftModalData.status,
                                submitted_by: employmentId
                            }).then(() => reloadShifts())
                        }}
                    >
                        確定する
                    </Button>
                </Stack>
            </div>
        )
    }
    useEffect(() => {
        if (Object.keys(shiftModalData).length > 0 && (shiftModalData.startTime || shiftModalData.endTime)) {
            if (shiftModalData.startTime.split(':')[1] % 15 > 0) {
                setStartError(true)
            } else {
                setStartError(false)
            }
            if (shiftModalData.endTime.split(':')[1] % 15 > 0) {
                setEndError(true)
            } else {
                setEndError(false)
            }
        }
    }, [shiftModalData])

    if (monthlyShift && Object.entries(monthlyShift).length > 0) {
        return (
            <>
                <TableBody>
                    {employments.map((employment, idx) => {
                        // employees list
                        return (
                            <TableRow key={employment.id}>
                                <MonthlyShiftLeftmostBlock
                                    employment={employment}
                                    dates={dates}
                                    monthlyShift={monthlyShift}
                                />
                                {dates.map(date => {
                                    // daily shift time range
                                    const shiftTimeCalc = shifts => {
                                        let timeToReturn = null
                                        Object.entries(shifts).map(shift => {
                                            if (parseInt(shift[0]) === employment.id) {
                                                Object.entries(shift[1]).map(shift => {
                                                    if (shift[0] === date) {
                                                        let start = shift[1]['shift_time'][0]
                                                        let end = shift[1]['shift_time'][1]
                                                        if (start[0] === '0') {
                                                            start = start.slice(1)
                                                        }
                                                        if (end[0] === '0') {
                                                            end = end.slice(1)
                                                        }
                                                        timeToReturn = `${start} - ${end}`
                                                    }
                                                })
                                            }
                                        })
                                        return timeToReturn
                                    }
                                    const shiftTime = () => {
                                        const submitted_shift = shiftTimeCalc(monthlyShift.submittedShifts)
                                        const confirmed_shift = shiftTimeCalc(monthlyShift.shifts)

                                        return confirmed_shift || submitted_shift
                                    }

                                    const isSubmittedShift = () => {
                                        let isSubmitted = false
                                        Object.entries(monthlyShift.submittedShifts).map(shifts => {
                                            if (parseInt(shifts[0]) === employment.id) {
                                                Object.entries(shifts[1]).map(shift => {
                                                    if (shift[0] === date) {
                                                        isSubmitted = true
                                                    }
                                                })
                                            }
                                        })
                                        return isSubmitted
                                    }

                                    const isConfirmedShift = () => {
                                        let isConfirmed = false
                                        Object.entries(monthlyShift.shifts).map(shifts => {
                                            if (parseInt(shifts[0]) === employment.id) {
                                                Object.entries(shifts[1]).map(shift => {
                                                    if (shift[0] === date) {
                                                        isConfirmed = true
                                                    }
                                                })
                                            }
                                        })
                                        return isConfirmed
                                    }
                                    const handleClick = e => {
                                        e.preventDefault()
                                        if (e.type === 'contextmenu' && isConfirmedShift()) {
                                            setShiftModalOpen(true)
                                            setAnchorEl(e.target)
                                            setShiftModalData({
                                                startTime: monthlyShift.shifts[employment.id][date]['shift_time'][0],
                                                endTime: monthlyShift.shifts[employment.id][date]['shift_time'][1],
                                                employment: employment,
                                                date: date,
                                                status: 'confirmed',
                                                shiftId: monthlyShift.shifts[employment.id][date]['id']
                                            })
                                        } else if (e.type === 'contextmenu' && isSubmittedShift()) {
                                            const startTime = new Date(
                                                date +
                                                    ' ' +
                                                    monthlyShift.submittedShifts[employment.id][date]['shift_time'][0]
                                            )
                                            let endTime = new Date(
                                                date +
                                                    ' ' +
                                                    monthlyShift.submittedShifts[employment.id][date]['shift_time'][1]
                                            )
                                            if (startTime.getHours() > endTime.getHours()) {
                                                endTime.setDate(endTime.getDate() + 1)
                                            }
                                            AxiosWithAuth.post('/shifts', {
                                                employment_id: employment.id,
                                                start_time: startTime,
                                                end_time: endTime,
                                                status: 'confirmed',
                                                submitted_by: employmentId,
                                                ops_date: date
                                            }).then(() => reloadShifts()).catch(err => {
                                                if (typeof err.response.data === 'object') {
                                                    setSnackbarError(Object.values(err.response.data).map(e => e).join('\n'))
                                                } else {
                                                    setSnackbarError('エラーが発生しました。')
                                                }
                                            })
                                        }
                                    }

                                    if (fixedCells.includes(date)) return null
                                    return (
                                        <TableCell
                                            key={date}
                                            sx={{
                                                ...desktopMonthlyBaseCellStyle,
                                                borderRight: '1px solid lightgray',
                                                borderBottom: '1px solid lightgray',
                                                borderTop: '1px solid lightgray',
                                                backgroundColor: computeBackgroundColor(date, monthlyShift, true),
                                                color: isConfirmedShift()
                                                    ? 'blue'
                                                    : isSubmittedShift()
                                                    ? 'gray'
                                                    : 'black'
                                            }}
                                            onClick={handleClick}
                                            onContextMenu={handleClick}
                                            value={[employment.id, date]}
                                            id={idx}
                                        >
                                            {shiftTime()}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        )
                    })}
                </TableBody>

                <Popover open={shiftModalOpen} onClose={() => setShiftModalOpen(false)} anchorEl={anchorEl}>
                    {editShiftModal()}
                </Popover>
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={4000}
                    onClose={() => setSnackbarOpen(false)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert onClose={() => setSnackbarOpen(false)}>シフトを保存しました。</Alert>
                </Snackbar>
                <Snackbar
                    open={snackbarError.length > 0}
                    autoHideDuration={4000}
                    onClose={() => setSnackbarError('')}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert severity="error" onClose={() => setSnackbarError('')}> {snackbarError}</Alert>
                </Snackbar>
            </>
        )
    } else {
        return <CircularProgress sx={{ mx: '50%', mt: '10%' }} />
    }
}

export default EditMonthlyShiftBody
